import { DonateHospital } from './modules/donate/donate-hospital';
import { globalDef } from './modules/site-definitions';
import { getUrlParam } from './modules/utility';
import Cookies from 'js-cookie';

/**
 * Fetch nearest hospital 
 * @page all
 */

// Check if the ip_hospital_id cookie already exists
let ipHospitalId = Cookies.get('ip_hospital_id');

if (!ipHospitalId) {
    const donateHospital = new DonateHospital();
    const hospital = await donateHospital.fetchNearestHospitalByIP();

    // Only set ip_hospital_id if a valid hospital is fetched
    if (hospital && hospital.length > 0) {
        ipHospitalId = hospital[0].hospitalId;

        Cookies.set('ip_hospital_id', ipHospitalId, {
            path: '/',
            domain: globalDef.mainDomain,
            secure: true,
            expires: 365
        });
    }
}

/**
 * Set hospital cookie if query param exists. This should run on all pages
 * @page all
 */
const hospitalIdCookie = Cookies.get('hospital_id');
const hospitalSelect = document.querySelector<HTMLSelectElement>('#hospital');
const hospitalInput = document.querySelector<HTMLInputElement>('[name="IpHospitalId"]');
const hospitalParam = getUrlParam('hospital') as string;

// Use `hospitalParam` first, then fallback to cookie or IP-based hospital:
const finalHospitalId = hospitalParam || hospitalIdCookie || ipHospitalId;

// Update hospital select
if (hospitalSelect && finalHospitalId && hospitalSelect.querySelector(`option[value="${finalHospitalId}"]`)) {
    hospitalSelect.value = finalHospitalId;

    if (hospitalInput) {
        hospitalInput.value = finalHospitalId;
    }
}

/**
 * Set ecode cookie if query param exists. This should run on all pages
 * @page all
 */
const ecodeParam = getUrlParam('ecode') as string;

// Ecode is not set and user visits with an ecode query param.
if (ecodeParam) {
    Cookies.set('ecode', ecodeParam, {
        path: '/',
        domain: globalDef.mainDomain,
        secure: true,
        expires: 1
    });
}

/**
 * Set app campaign cookie if query param exists. This should run on all pages
 * @page all
 */
const appcampaignParam = getUrlParam('appcampaignid') as string;

// App campaign is not set and user visits with an app campaign query param.
if (appcampaignParam) {
    Cookies.set('app_campaign_id', appcampaignParam, {
        path: '/',
        domain: globalDef.mainDomain,
        secure: true,
        expires: 1
    });
}

/**
 * Set campaign cookie if query param exists. This should run on all pages
 * @page all
 */
const campaignParam = getUrlParam('campaignid') as string;

// Campaign is not set and user visits with a campaign query param.
if (campaignParam) {
    Cookies.set('campaign_id', campaignParam, {
        path: '/',
        domain: globalDef.mainDomain,
        secure: true,
        expires: 1
    });
}
